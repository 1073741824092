import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem("user"); // Check if user is logged in
  const location = useLocation();  // Get the current URL location

  // Define public routes
  const publicRoutes = ["/","/signup", "/signin", "/forgetpass", "/emails"];

  // If the user is authenticated and tries to access a public route, show an alert and redirect
  if (isAuthenticated && publicRoutes.includes(location.pathname)) {
    alert("You are already logged in. Please log out to access this page.");
    return <Navigate to="/" />;  // Redirect to homepage or another appropriate page
  }

  // If the user is not authenticated, allow access to the protected routes
  return isAuthenticated ? children : <Navigate to="/signin" state={{ from: location }} />;
};

export default ProtectedRoute;
