const Page404 = () =>{
    return (
        <>
            <h1 className="m5 p-5 text-danger">404 page</h1>
            <h2>That page is not available</h2>
        </>
        
    )
}

export default Page404;