import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./ImageGrid.css";
import ImageGridMob from "./ImageGridMob";
const ImageGrid = () => {
  const imageStyle = {
    borderRadius: "15px",
    
    width: "100%",
    height: "100%",
  };
  return (
    <>
      <Container className="pc-img-grid">
        <Row>
          <Col md={4} lg={4} sm={4} className="mb-3 mb-md-0 col1">
            <div style={{ height: "400px", overflow: "hidden" }}>
              {" "}
              {/* Set a fixed height */}
              <Image
                src="/Gallery/2.jpeg"
                alt="Image 1"
                className="img-fluid"
                style={{
                  borderRadius: "15px",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }} // Ensure full width & height
              />
            </div>
          </Col>

          <Col md={8} lg={8} sm={8} className="mb-3 mb-md-0 col2">
            <div style={{ height: "400px", overflow: "hidden" }}>
              {" "}
              {/* Same fixed height */}
              <Image
                src="/Gallery/1.jpeg"
                alt="Image 2"
                className="img-fluid"
                style={{
                  borderRadius: "15px",
                  height: "100%",
                  width: "100%",
                  
                }} // Full width & height for the second image
              />
            </div>
          </Col>
        </Row>

        <Row className="align-items-stretch mt-3">
          <Col md={3} sm={3} lg={3} className="mb-3 mb-md-0">
          <div style={{ height: "400px", overflow: "hidden" }}>
              {" "}
              {/* Same fixed height */}
              <Image
                src="/Gallery/3.jpeg"
                alt="Image 2"
                className="img-fluid"
                style={{
                  borderRadius: "15px",
                  height: "100%",
                  width: "100%",
                  
                }} // Full width & height for the second image
              />
            </div>
          </Col>
          <Col md={6} sm={6} lg={6}>
          <div style={{ height: "400px", overflow: "hidden" }}>
              {" "}
              {/* Same fixed height */}
              <Image
                src="/Gallery/4.jpeg"
                alt="Image 2"
                className="img-fluid"
                style={{
                  borderRadius: "15px",
                  height: "100%",
                  width: "100%",
                  
                }} // Full width & height for the second image
              />
            </div>
          </Col>
          <Col md={3} sm={3} lg={3}>
          <div style={{ height: "400px", overflow: "hidden" }}>
              {" "}
              {/* Same fixed height */}
              <Image
                src="/Gallery/5.jpeg"
                alt="Image 2"
                className="img-fluid"
                style={{
                  borderRadius: "15px",
                  height: "100%",
                  width: "100%",
                  
                }} // Full width & height for the second image
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mob-img-grid" style={{ display: "none" }}>
        <ImageGridMob />
      </Container>
    </>
  );
};

export default ImageGrid;
