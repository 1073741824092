import { useEffect, useState } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import Index from "./Dryklin/Index";
import ProtectedRoute from "./Dryklin/Authentication/ProtectedRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// User Authentication Components
import SigninForm from "./Dryklin/Authentication/SigninForm";
import SignupForm from "./Dryklin/Authentication/SignupForm";
import EmailVerification from "./Dryklin/Authentication/EmailVerification";
import ForgotPassword from "./Dryklin/Authentication/ForgotPassword";

// Other Components
import ContactUs from "./Dryklin/ContactUs";
import AboutUs from "./Dryklin/AboutUs";
import PriceCalculator from "./Dryklin/PriceCalculator";
import Address from "./Dryklin/Address/Address";
import Wallet from "./Dryklin/Address/Wallet";
import Page404 from "./Dryklin/Page404";
import BillingModal from "./Dryklin/BillingModal";
import TermsAndConditions from "./Dryklin/TermsAndConditions";
import CustomCare from "./Dryklin/CustomerCare";
import PrivacyPolicy from "./Dryklin/PrivacyPolicy";
import Faq from "./Dryklin/Faq";
import Profile from "./Dryklin/Address/Profile";
import ImageUpload from "./Dryklin/New/ImageUpload";
import ImageViewer from "./Dryklin/New/ImageViewer";
import PaymentSuccess from "./Dryklin/Address/PaymentSuccess";
import Loader from "./Dryklin/Authentication/Loader";

function App() {
  const [user, setUser] = useState(null);  // State to track user
  const navigate = useNavigate();          // Initialize useNavigate

  useEffect(() => {
    // Check if user is in localStorage
    const storedUser = localStorage.getItem("user");
    
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);  // Set user if found in localStorage
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
        localStorage.clear();  // Clear invalid user data
        navigate("/signin");   // Redirect to login if error occurs
      }
    } else {
      // No user found, redirect to signin
      // navigate("/signin");
    }
  }, [navigate]);  // Dependency on navigate

  return (
    <div>
      <Routes>
        {/* Authentication Routes */}
        <Route path="/signin" element={<SigninForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/forgetpass" element={<ForgotPassword />} />
        <Route path="/emails" element={<EmailVerification />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route
          path="/"
          element={

              <Loader>
                <Index />
              </Loader>

          }
        />

        {/* Protected Routes */}
        {/* <Route
          path="/"
          element={
            <ProtectedRoute>
              <Loader>
                <Index />
              </Loader>
            </ProtectedRoute>
          }
        /> */}
        <Route path="/pricelist" element={<ProtectedRoute><PriceCalculator /></ProtectedRoute>} />
        <Route path="/address" element={<ProtectedRoute><Address /></ProtectedRoute>} />
        <Route path="/wallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
        <Route path="/paynow" element={<ProtectedRoute><BillingModal /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/terms" element={<ProtectedRoute><TermsAndConditions /></ProtectedRoute>} />
        <Route path="/customcare" element={<ProtectedRoute><CustomCare /></ProtectedRoute>} />
        <Route path="/privacy" element={<ProtectedRoute><PrivacyPolicy /></ProtectedRoute>} />
        <Route path="/faq" element={<ProtectedRoute><Faq /></ProtectedRoute>} />
        <Route path="/imageupload" element={<ProtectedRoute><ImageUpload /></ProtectedRoute>} />
        <Route path="/view/:imageName" element={<ProtectedRoute><ImageViewer /></ProtectedRoute>} />
        <Route path="/payment-success" element={<ProtectedRoute><PaymentSuccess /></ProtectedRoute>} />

        {/* Catch-All Route */}
        <Route path="*" element={<ProtectedRoute><Page404 /></ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;
